import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  Form,
  Select,
  Row,
  Col,
  Modal,
  Spin,
  message,
  Divider /* , Checkbox */,
} from 'antd';
import { format } from 'date-fns';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';
import { useWorkflow } from 'hooks/useWorkflow';
import { validateCpf, validateCnpj } from 'utils/validations';

import AdditionalInfo from 'components/AdditionalInfo';
import IntlAdditionalInfo from 'components/IntlAdditionalInfo';
import Button from 'components/Button';
import FileUpload from 'components/Files/FileUpload';
import PageTitle from 'components/PageTitle';

import {
  optionalSources,
  requiredImagesSources,
  requiredSelfieSources,
  supportedFiles,
} from './utils/fileUpload';

import './styles.less';

const { Option, OptGroup } = Select;
const I18N_BASE_PATH = 'components.modalCreateExecution.index.CreateExecution';
const I18N_BASE_PATH_PERSON_REGISTER = 'components.modalProfiles.personRegister.index';

const CreateNewRegister = ({ pageTitle, buttonsText, validationType, segment }) => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();

  const {
    getWorkflowsData,
    data: _workflowsData,
    loading: loadingWorkflows,
  } = useWorkflow();

  const { user } = useAuth();
  const history = useHistory();

  const { post: postNewRegister, loading: loadingNewRegisster } = useFetch();
  const {
    data: templateServicesList,
    get: getTemplateServicesList,
    loading: loadingServicesList,
  } = useFetch();

  const {
    loadProfileTemplates,
    allProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const [filesIntl, setFilesIntl] = useState({});
  const [filesRequired, setFilesRequired] = useState({});
  const [missingFiles, setMissingFiles] = useState({
    front: false,
    back: false,
    selfie: false,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [profileTemplateSelected, setProfileTemplateSelected] = useState({});
  const [workflowSelected, setWorkflow] = useState({});

  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [selfieFile, setSelfieFile] = useState(null);
  const [missingFrontFile, setMissingFrontFile] = useState(false);
  const [missingSelfieFile, setMissingSelfieFile] = useState(false);

  // const [showImagesUploadCard, setShowImagesUploadCard] = useState(false);

  const workflowsData = useMemo(() => {
    if (!segment || !_workflowsData) return _workflowsData;

    return {
      ..._workflowsData,
      docs: _workflowsData?.docs?.filter((wf) => wf?.segment === segment),
    };
  }, [_workflowsData, segment]);

  const allProfileTemplatesActive = useMemo(() => {
    const profileTemplatesFiltered = allProfileTemplates?.filter((p) => p?.active);

    return profileTemplatesFiltered || [];
  }, [allProfileTemplates]);

  const fetchWorkflows = useCallback(async () => {
    await getWorkflowsData({ tenantId: user?.tenantId, _limit: 1000 });
  }, [getWorkflowsData, user]);

  const fetchProfileTemplates = useCallback(() => {
    loadProfileTemplates({});
  }, [loadProfileTemplates]);

  useEffect(() => {
    if (modalVisible) {
      getTemplateServicesList({ url: '/template-services', showMessage: false });
      if (hasFeatureFlag('isWorkflowBuilder', user)) fetchWorkflows();
      else {
        fetchProfileTemplates();
      }
    }
  }, [
    getTemplateServicesList,
    modalVisible,
    fetchWorkflows,
    user,
    fetchProfileTemplates,
  ]);
  const groupedProfileTemplates = useMemo(() => {
    if (loadingAllProfileTemplates || !allProfileTemplatesActive) return null;
    const pf = [];
    const pj = [];

    allProfileTemplatesActive?.forEach((template) => {
      if (template.type === 'PF') pf.push(template);
      if (template.type === 'PJ') pj.push(template);
    });

    return { pf, pj };
  }, [allProfileTemplatesActive, loadingAllProfileTemplates]);
  const showProfileTemplateLabelType = useMemo(() => {
    const showPFProfileTemplates = allProfileTemplatesActive?.some(
      (p) => p?.type === 'PF'
    );

    const showPJProfileTemplates = allProfileTemplatesActive?.some(
      (p) => p?.type === 'PJ'
    );

    return { showPFProfileTemplates, showPJProfileTemplates };
  }, [allProfileTemplatesActive]);

  const workFlowOptions = useMemo(() => {
    return (
      <>
        {workflowsData && hasFeatureFlag('isWorkflowBuilder', user) && (
          <OptGroup label="Workflows">
            {workflowsData?.docs?.map((wf) => (
              <Option key={wf._id} value={wf._id}>
                {wf?.definition.name}
              </Option>
            ))}
          </OptGroup>
        )}
      </>
    );
  }, [user, workflowsData]);
  const optionalFields = useMemo(() => {
    const onboardingServices = profileTemplateSelected?.onboarding?.services || [];

    return (
      Array.isArray(profileTemplateSelected?.onboarding?.services) &&
      onboardingServices?.some((o) => optionalSources.includes(o?.source))
    );
  }, [profileTemplateSelected]);

  const selectedProfileTemplateServices = useMemo(() => {
    const onboardingServices = profileTemplateSelected?.onboarding?.services || [];
    const bgCheckingServices =
      profileTemplateSelected?.backgroundChecking?.services || [];

    return {
      onboardingServices,
      bgCheckingServices,
    };
  }, [profileTemplateSelected]);

  const selectedProductServices = useMemo(() => {
    const onboardingServicesProduct =
      templateServicesList?.docs?.find(
        (t) => t?.source === profileTemplateSelected?.product
      )?.template?.onboarding?.services || [];

    const bgCheckingServicesProduct =
      templateServicesList?.docs?.find(
        (t) => t?.source === profileTemplateSelected?.product
      )?.template?.backgroundChecking?.services || [];

    return {
      onboardingServicesProduct,
      bgCheckingServicesProduct,
    };
  }, [profileTemplateSelected, templateServicesList]);

  const workflowVerify = useCallback(() => {
    setFilesIntl({});
    setMissingFiles({});
    setFilesRequired({});
    const workflow = workflowsData.docs.find(
      (wf) => wf._id === form.getFieldValue('workflowId')
    );

    setWorkflow(workflow);
  }, [workflowsData, form]);

  const requireDocumentsServices = useMemo(() => {
    const { onboardingServices, bgCheckingServices } = selectedProfileTemplateServices;

    const {
      onboardingServicesProduct,
      bgCheckingServicesProduct,
    } = selectedProductServices;

    // just for retrocompaibilities
    let requiredInputs = [];

    if (Array.isArray(workflowSelected?.requiredInputs)) {
      requiredInputs = workflowSelected?.requiredInputs;
    } else {
      requiredInputs = workflowSelected?.requiredInputs?.default || [];
    }

    return (
      onboardingServices?.some((o) => requiredImagesSources.includes(o?.source)) ||
      bgCheckingServices?.some((o) => requiredImagesSources.includes(o?.source)) ||
      onboardingServicesProduct?.some((o) => requiredImagesSources.includes(o?.source)) ||
      bgCheckingServicesProduct?.some((o) => requiredImagesSources.includes(o?.source))
    );
  }, [selectedProfileTemplateServices, selectedProductServices, workflowSelected]);

  const requireSelfieServices = useMemo(() => {
    const { onboardingServices, bgCheckingServices } = selectedProfileTemplateServices;

    const {
      onboardingServicesProduct,
      bgCheckingServicesProduct,
    } = selectedProductServices;

    // just for retrocompaibilities
    let requiredInputs = [];

    if (Array.isArray(workflowSelected?.requiredInputs)) {
      requiredInputs = workflowSelected?.requiredInputs;
    } else {
      requiredInputs = workflowSelected?.requiredInputs?.default || [];
    }

    return (
      onboardingServices?.some((o) => requiredSelfieSources.includes(o?.source)) ||
      bgCheckingServices?.some((o) => requiredSelfieSources.includes(o?.source)) ||
      onboardingServicesProduct?.some((o) => requiredSelfieSources.includes(o?.source)) ||
      bgCheckingServicesProduct?.some((o) => requiredSelfieSources.includes(o?.source))
    );
  }, [selectedProductServices, selectedProfileTemplateServices, workflowSelected]);

  const isFacialBiometrics = useMemo(() => {
    return (
      (profileTemplateSelected?.onboarding?.services[0]?.source === 'official_data' &&
        profileTemplateSelected?.onboarding?.services?.length === 1 &&
        profileTemplateSelected?.product !== 'AUTOMACAO_ONBOARDING') ||
      profileTemplateSelected?.backgroundChecking?.services?.some((service) => {
        return (
          service?.source === 'pf_biometria_serasa' ||
          service?.source === 'pf_biometria_vivo' ||
          service?.source === 'pf_biometria_id_unico'
        );
      })
    );
  }, [profileTemplateSelected]);

  const profileTemplateHasExtraDataVerify = useCallback(() => {
    const profileTemplate = allProfileTemplates.find(
      (pt) => pt._id === form.getFieldValue('profileTemplateId')
    );
    setProfileTemplateSelected(profileTemplate);
  }, [allProfileTemplates, form]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setTimeout(() => {
      setMissingFrontFile(false);
      setMissingSelfieFile(false);
      setFrontFile(null);
      setBackFile(null);
      setSelfieFile(null);
      setFilesIntl({});
      setMissingFiles({});
      setFilesRequired({});
      setProfileTemplateSelected({});
      setWorkflow({});
      form.resetFields();
    }, 2000);
  }, [form]);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const verifyFilesMissing = useCallback(() => {
    let haveMissingFiles = false;
    const missingFilesArray = [];
    if (!filesIntl?.front && filesRequired?.front) {
      missingFilesArray.push('front');
      haveMissingFiles = true;
    }
    if (!filesIntl?.selfie && filesRequired?.selfie) {
      missingFilesArray.push('selfie');
      haveMissingFiles = true;
    }
    if (!filesIntl?.back && filesRequired?.back) {
      missingFilesArray.push('back');
      haveMissingFiles = true;
    }
    if (haveMissingFiles) {
      setMissingFiles({
        back: missingFilesArray?.includes('back'),
        front: missingFilesArray?.includes('front'),
        selfie: missingFilesArray?.includes('selfie'),
      });
    }
  }, [filesIntl, filesRequired]);
  const submit = useCallback(
    async (payload) => {
      try {
        if (
          profileTemplateSelected?.type === 'PF' ||
          workflowSelected?.segment === 'person'
        ) {
          if (!optionalFields && payload.cpf && !validateCpf(payload.cpf)) {
            message.error(
              translate(`${I18N_BASE_PATH}.submit.messages.validateCpfError`)
            );
            return;
          }
          const files = [];
          if (requireDocumentsServices) {
            if (frontFile) {
              let typeFront;
              if (payload?.documentType) {
                typeFront =
                  payload.documentType === 'other'
                    ? undefined
                    : `${payload.documentType}_FRONT`;
              } else {
                typeFront = 'FRONT';
              }
              files.push({
                data: frontFile,
                type: typeFront,
              });
            } else if (!isFacialBiometrics) {
              setMissingFrontFile(true);
            }
            if (backFile) {
              let typeBack;
              if (payload?.documentType) {
                typeBack =
                  payload.documentType === 'other'
                    ? undefined
                    : `${payload.documentType}_BACK`;
              } else {
                typeBack = 'BACK';
              }
              files.push({
                data: backFile,
                type: typeBack,
              });
            }
            if (!frontFile && !isFacialBiometrics) return;
          }

          if (selfieFile) {
            files.push({
              data: selfieFile,
              type: 'SELFIE',
            });
          }

          if (requireSelfieServices && !selfieFile) {
            setMissingSelfieFile(true);
            return;
          }
          let cancelSubmit = false;
          const missingFilesArray = [];
          if (filesIntl?.front) {
            files.push({
              data: filesIntl?.front,
              type: 'FRONT',
            });
          } else if (filesRequired?.front) {
            missingFilesArray.push('front');
            cancelSubmit = true;
          }
          if (filesIntl?.selfie) {
            files.push({
              data: filesIntl?.selfie,
              type: 'SELFIE',
            });
          } else if (filesRequired?.selfie) {
            missingFilesArray.push('selfie');
            cancelSubmit = true;
          }
          if (filesIntl?.back) {
            files.push({
              data: filesIntl?.back,
              type: 'BACK',
            });
          } else if (filesRequired?.back) {
            missingFilesArray.push('back');
            cancelSubmit = true;
          }
          if (cancelSubmit || missingFilesArray?.lenght) {
            setMissingFiles({
              back: missingFilesArray?.includes('back'),
              front: missingFilesArray?.includes('front'),
              selfie: missingFilesArray?.includes('selfie'),
            });
            return;
          }

          await postNewRegister({
            url: '/executions',
            payload: {
              attributes: {
                cpf: validateCpf(payload.cpf)
                  ? payload.cpf?.replace(/\D/g, '')
                  : undefined,
                birthDate: payload.birthDate,
                dateFormat: 'dd/MM/yyyy',
                personId: payload?.personId,
                name: payload?.name?.toUpperCase(),
                motherName: payload?.motherName?.toUpperCase(),
                fatherName: payload?.fatherName?.toUpperCase(),
                rgIssuingAuthority: payload?.rgIssuingAuthority?.toUpperCase(),
                uf: payload?.uf?.toUpperCase(),
                rgIssueDate: payload?.rgIssueDate || payload?.issueDate,
                issueDate: payload?.issueDate,
                rg: payload?.rg?.toUpperCase(),
                nit: payload?.nit,
                cnhRegistrationNumber: payload?.cnhRegistrationNumber,
                cnhRenachNumber: payload?.cnhRenachNumber,
                cnhMirrorNumber: payload?.cnhMirrorNumber,
                cnhFirstDriveLicenseDate: payload?.cnhFirstDriveLicenseDate,
                cnhExpirationDate: payload?.cnhExpirationDate,
                plate: payload?.plate,
                cep: payload?.cep,
                cnpj: payload?.cnpj?.replace(/\D/g, ''),
                coordinates:
                  payload?.latitude && payload?.longitude && payload?.accuracy
                    ? {
                        latitude: Number(payload.latitude),
                        longitude: Number(payload.longitude),
                        accuracy: Number(payload.accuracy),
                      }
                    : undefined,
                userIp: payload?.userIp,
                email: payload?.email,
                deviceId: payload?.deviceId,
                phoneNumber: payload?.phoneNumber,
                address: payload?.address,
                addressNumber: payload?.addressNumber
                  ? Number(payload?.addressNumber)
                  : undefined,
                accountId: payload?.accountId,
                originatingIp: payload?.originatingIp,
                accountLifecycleEvent: payload?.accountLifecycleEvent,
                nationalId: payload?.nationalId,
                documentNumber: payload?.documentNumber,
                zipCode: payload?.zipCode,
                countryCode: payload?.country,
              },
              profileTemplateId:
                payload.profileTemplateId === 'null' ? null : payload.profileTemplateId,

              templateId:
                payload.profileTemplateId === 'null' ? null : payload.profileTemplateId,
              workflowId: payload.workflowId === 'null' ? null : payload.workflowId,
              files: files?.length > 0 ? files : undefined,
            },
          });

          message.success(translate(`${I18N_BASE_PATH}.submit.messages.success`));

          closeModal();

          if (
            workflowSelected?.segment === 'person' &&
            hasFeatureFlag('isWorkflowBuilder', user)
          ) {
            history.push('/person');
          } else {
            history.push('/executions');
          }
        } else {
          if (payload.cnpj && !validateCnpj(payload.cnpj)) {
            message.error(
              translate(`${I18N_BASE_PATH}.submit.messages.validateCnpjError`)
            );
            return;
          }

          await postNewRegister({
            url: '/executions',
            payload: {
              attributes: {
                companyId: payload.companyId,
                cnpj: payload.cnpj?.replace(/\D/g, ''),
                uf: payload?.uf?.toUpperCase(),
                plate: payload?.plate,
                cep: payload?.cep,
                cpf: payload.cpf?.replace(/\D/g, ''),
                birthDate: payload.birthDate,
                dateFormat: 'dd/MM/yyyy',
                name: payload?.name?.toUpperCase(),
                coordinates:
                  payload?.latitude && payload?.longitude && payload?.accuracy
                    ? {
                        latitude: Number(payload.latitude),
                        longitude: Number(payload.longitude),
                        accuracy: Number(payload.accuracy),
                      }
                    : undefined,
                userIp: payload?.userIp,
                email: payload?.email,
                deviceId: payload?.deviceId,
                phoneNumber: payload?.phoneNumber,
                documentNumber: payload?.documentNumber,
                countryCode: payload?.country,
              },
              profileTemplateId:
                payload.profileTemplateId === 'null' ? null : payload.profileTemplateId,
              workflowId: payload.workflowId === 'null' ? null : payload.workflowId,
              templateId:
                payload.profileTemplateId === 'null' ? null : payload.profileTemplateId,
            },
          });

          message.success(translate(`${I18N_BASE_PATH}.submit.messages.success`));
          closeModal();

          if (hasFeatureFlag('isWorkflowBuilder', user)) {
            history.push('/company');
          } else {
            history.push('/executions');
          }
        }
      } catch (err) {
        //
      }
    },
    [
      profileTemplateSelected,
      workflowSelected,
      optionalFields,
      selfieFile,
      requireSelfieServices,
      filesIntl,
      filesRequired,
      postNewRegister,
      closeModal,
      translate,
      frontFile,
      isFacialBiometrics,
      backFile,
      history,
      requireDocumentsServices,
      user,
    ]
  );

  const customLabel = useMemo(
    () => (
      <div className="custom-label-content">
        {hasFeatureFlag('isWorkflowBuilder', user) ? (
          <>
            <span>{translate(`${I18N_BASE_PATH}.customLabel.titleWorkflow`)}</span>
            <Link to="/workflows" onClick={closeModal}>
              {translate(
                `pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.createLinkType.index.customProfileTemplateLabel.linkWorkflow` // => Criar Workflow
              )}
            </Link>
          </>
        ) : (
          <>
            <span>{translate(`${I18N_BASE_PATH}.customLabel.title`)}</span>
            <Link
              to="/profile-templates/profile-models/registration?step=start"
              onClick={closeModal}
            >
              {translate(
                `pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.createLinkType.index.customProfileTemplateLabel.link` // => Criar modelo
              )}
            </Link>
          </>
        )}
      </div>
    ),
    [closeModal, translate, user]
  );

  const handleChangeFile = useCallback((fileField, value) => {
    if (fileField === 'front') {
      setFrontFile(value);
    }
    if (fileField === 'back') {
      setBackFile(value);
    }
    if (fileField === 'selfie') {
      setSelfieFile(value);
    }
  }, []);

  // for retro purpuse
  let requiredInputs = [];
  if (Array.isArray(workflowSelected?.requiredInputs)) {
    requiredInputs = workflowSelected?.requiredInputs;
  } else {
    requiredInputs = workflowSelected?.requiredInputs?.default || [];
  }

  let optionalInputs = [];
  if (Array.isArray(workflowSelected?.optionalInputs)) {
    optionalInputs = workflowSelected?.optionalInputs;
  } else {
    optionalInputs = workflowSelected?.optionalInputs?.default || [];
  }

  const CreateNewRegisterModal = useMemo(
    () => (
      <Modal
        form={form}
        visible={modalVisible}
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={740}
      >
        <div id="create-new-register-modal-component" className="no-mrg no-pdd">
          <PageTitle title={pageTitle?.title} subtitle={pageTitle?.subtitle} />

          <Form
            form={form}
            layout="vertical"
            className="pdd-top-10"
            onFinish={submit}
            onFinishFailed={verifyFilesMissing}
          >
            {(loadingAllProfileTemplates || loadingServicesList || loadingWorkflows) && (
              <Spin className="flex center gx-w-100 mrg-top-30 mrg-btm-50" />
            )}

            {!loadingAllProfileTemplates &&
              !loadingServicesList &&
              !hasFeatureFlag('isWorkflowBuilder', user) && (
                <>
                  <Row className="mrg-top-5">
                    <Col span={24}>
                      <Form.Item
                        name="profileTemplateId"
                        label={customLabel}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder={translate(
                            `${I18N_BASE_PATH}.form.select.profileTemplateId.placeholder`
                          )}
                          onSelect={profileTemplateHasExtraDataVerify}
                        >
                          {groupedProfileTemplates?.pf &&
                            showProfileTemplateLabelType?.showPFProfileTemplates && (
                              <OptGroup
                                label={translate(
                                  `${I18N_BASE_PATH_PERSON_REGISTER}.personRegister.form.profileTemplateId.select.person`
                                )}
                              >
                                {groupedProfileTemplates?.pf?.map((template) => (
                                  <Option key={template._id} value={template._id}>
                                    {template.name}
                                  </Option>
                                ))}
                              </OptGroup>
                            )}
                          {groupedProfileTemplates?.pj &&
                            showProfileTemplateLabelType?.showPJProfileTemplates && (
                              <OptGroup
                                label={translate(
                                  `${I18N_BASE_PATH_PERSON_REGISTER}.personRegister.form.profileTemplateId.select.company`
                                )}
                              >
                                {groupedProfileTemplates?.pj?.map((template) => (
                                  <Option key={template._id} value={template._id}>
                                    {template.name}
                                  </Option>
                                ))}
                              </OptGroup>
                            )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            {!loadingWorkflows &&
              !loadingServicesList &&
              hasFeatureFlag('isWorkflowBuilder', user) && (
                <IntlAdditionalInfo
                  files={filesIntl}
                  setFiles={setFilesIntl}
                  type={workflowSelected?.segment}
                  countryCodes={workflowSelected?.countryCodes}
                  requiredInputs={workflowSelected?.requiredInputs}
                  optionalInputs={workflowSelected?.optionalInputs}
                  setFilesRequired={setFilesRequired}
                  missingFiles={missingFiles}
                  form={form}
                >
                  <Form.Item
                    name="workflowId"
                    label={customLabel}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select workflow"
                      onSelect={workflowVerify}
                    >
                      {workFlowOptions}
                    </Select>
                  </Form.Item>
                </IntlAdditionalInfo>
              )}
            {(requireDocumentsServices || requireSelfieServices) &&
              !hasFeatureFlag('isWorkflowBuilder', user) && (
                <>
                  <Divider className="text-bold mrg-top-5">
                    {translate(`${I18N_BASE_PATH}.form.fileUpload.divider.title`)}
                  </Divider>
                  {requireDocumentsServices &&
                    !hasFeatureFlag('isWorkflowBuilder', user) &&
                    !isFacialBiometrics && (
                      <Row className="mrg-btm-10">
                        <Col span={12}>
                          <Form.Item
                            initialValue="nacional"
                            name="documentOrigin"
                            label={translate(
                              `${I18N_BASE_PATH}.form.select.documentOrigin.label`
                            )}
                            rules={[{ required: true }]}
                          >
                            <Select
                              optionFilterProp="children"
                              placeholder={translate(
                                `${I18N_BASE_PATH}.form.select.documentOrigin.placeholder`
                              )}
                            >
                              <Option default value="nacional">
                                {translate(
                                  `${I18N_BASE_PATH}.form.select.documentOrigin.options.national`
                                )}
                              </Option>
                              <Option disabled value="estrangeiro">
                                {translate(
                                  `${I18N_BASE_PATH}.form.select.documentOrigin.options.foreign`
                                )}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="documentType"
                            label={translate(
                              `${I18N_BASE_PATH}.form.select.documentType.label`
                            )}
                            rules={[
                              {
                                required: true,
                                message: translate(
                                  `${I18N_BASE_PATH}.form.rules.messages.documentType`
                                ),
                              },
                            ]}
                          >
                            <Select
                              optionFilterProp="children"
                              placeholder={translate(
                                `${I18N_BASE_PATH}.form.select.documentType.placeholder`
                              )}
                            >
                              <Option default value="RG">
                                {translate(
                                  `${I18N_BASE_PATH}.form.select.documentType.options.rg`
                                )}
                              </Option>
                              <Option default value="CNH">
                                {translate(
                                  `${I18N_BASE_PATH}.form.select.documentType.options.cnh`
                                )}
                              </Option>
                              <Option default value="other">
                                {translate(
                                  `${I18N_BASE_PATH}.form.select.documentType.options.other`
                                )}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col span={24}>
                      {requireDocumentsServices && (
                        <Form.Item
                          label={
                            !isFacialBiometrics
                              ? translate(`${I18N_BASE_PATH}.form.fileUpload.front.label`)
                              : translate(
                                  `${I18N_BASE_PATH}.form.fileUpload.biometrics.label`
                                )
                          }
                        >
                          {(workflowSelected?.requiredInputs?.includes('front') ||
                            !hasFeatureFlag('isWorkflowBuilder', user)) && (
                            <>
                              <FileUpload
                                title={translate(
                                  `${I18N_BASE_PATH}.form.fileUpload.front.title`
                                )}
                                isInvalid={missingFrontFile && !frontFile}
                                on
                                file={frontFile}
                                onChangeFile={(value) => handleChangeFile('front', value)}
                                supportedFiles={supportedFiles}
                              />
                            </>
                          )}
                          {(workflowSelected?.requiredInputs?.includes('back') ||
                            workflowSelected?.optionalInputs?.includes('back') ||
                            !hasFeatureFlag('isWorkflowBuilder', user)) &&
                            !isFacialBiometrics && (
                              <>
                                <FileUpload
                                  title={translate(
                                    `${I18N_BASE_PATH}.form.fileUpload.back.title`
                                  )}
                                  file={backFile}
                                  onChangeFile={(value) =>
                                    handleChangeFile('back', value)
                                  }
                                  supportedFiles={supportedFiles}
                                />
                              </>
                            )}
                        </Form.Item>
                      )}
                      {isFacialBiometrics && (
                        <Divider className="text-bold">
                          {translate(`${I18N_BASE_PATH}.form.fileUpload.biometrics.or`)}
                        </Divider>
                      )}
                      <Form.Item
                        className="mrg-btm-10"
                        label={translate(
                          `${I18N_BASE_PATH}.form.fileUpload.selfie.label`
                        )}
                      >
                        <FileUpload
                          title={translate(
                            `${I18N_BASE_PATH}.form.fileUpload.selfie.title`
                          )}
                          isInvalid={missingSelfieFile && !selfieFile}
                          file={selfieFile}
                          onChangeFile={(value) => handleChangeFile('selfie', value)}
                          supportedFiles={supportedFiles}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            {profileTemplateSelected?.type && !hasFeatureFlag('isWorkflowBuilder', user) && (
              <>
                <Divider className="text-bold mrg-top-5">
                  {translate(`${I18N_BASE_PATH}.form.additionalInfo.title`)}
                </Divider>

                <AdditionalInfo
                  isNew
                  showAlertInfo
                  allOptional={optionalFields}
                  requiredFieldSpecial={false}
                  type={profileTemplateSelected?.type}
                  services={profileTemplateSelected?.backgroundChecking?.services}
                />
              </>
            )}

            <div className="gx-flex-row gx-justify-content-end mrg-top-20">
              <Button type="default" onClick={closeModal} className="btn-cancel">
                {translate(`${I18N_BASE_PATH}.form.button.cancel`)}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loadingNewRegisster || loadingAllProfileTemplates}
                loading={loadingNewRegisster}
              >
                {loadingNewRegisster ? buttonsText?.loading : buttonsText?.default}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    ),
    [
      form,
      modalVisible,
      closeModal,
      pageTitle,
      submit,
      verifyFilesMissing,
      loadingAllProfileTemplates,
      loadingServicesList,
      loadingWorkflows,
      user,
      customLabel,
      translate,
      profileTemplateHasExtraDataVerify,
      groupedProfileTemplates,
      showProfileTemplateLabelType,
      workflowVerify,
      workFlowOptions,
      requireDocumentsServices,
      requireSelfieServices,
      isFacialBiometrics,
      workflowSelected,
      missingFrontFile,
      frontFile,
      backFile,
      missingSelfieFile,
      selfieFile,
      filesIntl,
      missingFiles,
      profileTemplateSelected,
      optionalFields,
      loadingNewRegisster,
      buttonsText,
      handleChangeFile,
    ]
  );

  return { openModal, closeModal, CreateNewRegisterModal };
};

export default CreateNewRegister;
